// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.css';

// If you want to add something do it here
@import "custom";

@import "~ngx-toastr/toastr";
@import "~@ng-select/ng-select/themes/default.theme.css";

// @import '~bs-stepper/dist/css/bs-stepper.min.css';

.main .container-fluid {
  padding: 0 10px;
  margin-bottom: 0;
}


.dropdown-item {
  cursor: pointer;
}
.breadcrumb {
  margin-bottom: 14px;
}

ngb-modal-backdrop.modal-backdrop.fade.show {
  z-index: 1020 !important;
}

.form-select {
  padding: 0.25rem 2.25rem 0.25rem 0.75rem !important;
}

.btn:disabled {
  cursor: not-allowed !important;
}

.nav-logo {
  width: 90px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 16px;
}

@media(max-width:768px) {
  .nav-logo {
    width: 200px;
  }
}

@media(max-width:425px) {
  .app-header .navbar-brand {
    left: 36%;
  }

  .nav-logo {
    width: 160px;
  }
}

.spinner {
  width: 11.2px;
  height: 11.2px;
  border-radius: 11.2px;
  box-shadow: 28px 0px 0 0 rgba(15,128,61,0.2), 22.7px 16.5px 0 0 rgba(15,128,61,0.4), 8.68px 26.6px 0 0 rgba(15,128,61,0.6), -8.68px 26.6px 0 0 rgba(15,128,61,0.8), -22.7px 16.5px 0 0 #0f803d;
  animation: spinner-b87k6z 1s infinite linear;
}

@keyframes spinner-b87k6z {
  to {
     transform: rotate(360deg);
  }
}